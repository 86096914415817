/* Фидбек, всплывающее окно */
(function () {
  var $body = $('body')
  var $w = $(window)

  function close_feedback_popup(event) {
    var $target = $(event.target)

    if ($target.is('.js-popup') === false && $target.closest('.js-popup').length === 0) {
      $body.off('click.deleteJsPopup')
      $('.js-popup').remove()
      $('.page__wrapper').find('.s-menu-multilevel__overlay').fadeOut('fast')
    }
  }

  $body
    .on('submit', '#feedback_form', function (e) {
      e.preventDefault()
      var $form = $(this)

      $('#feedback_ref_url').val(location.href)

      $form
        .attr('action', $form.data('ctn'))
        .ajaxSubmit({
          target : '#feedback_form',
          success: function (url, result) {
            if (result === 'success' && url.indexOf('http') === 0) {
              $('#feedback_form').css('color', '#fff')
              const event = new window.gtmEvents.GenerateLeadEvent
              event.dispatch({ lead_type: 'feedback_form', subject: 'general questions' })
              $body.trigger('click.deleteJsPopup')
            } else {
              window.input_suffixed_initialize()
            }
          }
        })
    })
    .on('click', '.js-popup', function (event) {
      event.stopImmediatePropagation()
    })
    .on('click', '.feedback-button', function (e) {
      e.preventDefault()
      e.stopImmediatePropagation()

      var $btn = $(this)
      var module = $btn.data('module') || 'feedback'

      var $popup = $('.js-popup')
      if ($popup.length > 0) {
        $body.trigger('click.deleteJsPopup')
        return
      }

      var $win = $('<div class="js-popup"><button class="js-popup__close"></button>' + $.tera.byId('feedback_form_tpl', { module: module }) + '</div>')
      var $countdownNotice = $('.js-countdown-init')

      $win.css({ top: 85 + ($countdownNotice.length > 0 ? $countdownNotice.height() : 0), left: '50%', marginLeft: '-440px', width: 800 })
      var $wrap = $('.page__wrapper')
      var $overlay = $wrap.find('.s-menu-multilevel__overlay')
      if ($overlay.length === 0) {
        $overlay = $('<div class="s-menu-multilevel__overlay"></div>')
        $wrap.append($overlay)
      }

      $overlay.fadeIn('fast')

      $body
        .append($win)
        .find('#feedback_form')
        .find('textarea')
        .trigger('focus')
        .trigger('select')

      window.input_suffixed_initialize()
      if ($w.width() < 980) {
        $('html, body').animate({ scrollTop: 50 }, 600)
      }

      $body.on('click.deleteJsPopup', close_feedback_popup)

      $win
        .find('.js-popup__close, .js-close-popup').bind('click', function () {
          $body.trigger('click.deleteJsPopup')
        })
    })
})()
